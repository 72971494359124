<template>
  <div id="task-browse">
    <Portal to="primary-action">
      <BaseButton
        v-if="showAddTask"
        icon="eva-plus"
        label="add task"
        class="q-ml-md"
        :disabled="addTaskDisabled"
        @click="addTasksList"
      />
    </Portal>

    <Portal to="action-bar">
      <ItemActions
        item="task"
        :columns.sync="columns"
        :group-by.sync="groupBy"
        :order.sync="sortBy.order"
        :filter-by.sync="filterBy"
        :active-view.sync="activeView"
        :kanban-view="true"
        :criteria.sync="sortBy.criteria"
        :grid-view="false"
        :active-kanban-view="activeView"
        :kanban-list="kanbanList"
        module="form"
        :module-id="formId"
        :simple-search-applyed.sync="simpleSearchApplyed"
        @refresh="getTaskEntries"
        @update:activeKanbanView="activeKanbanView"
        @search="searchRow"
        @update="getTaskEntries"
      />
    </Portal>

    <div class="content">
      <template v-if="totalItems">
        <ItemActionChips
          :sort-by="sortBy"
          :columns="columns"
          :group-by="groupBy"
          :filter-by="filterBy"
          :simple-search-applyed="simpleSearchApplyed"
          @clearSortBy="clearSortBy"
          @clearGroupBy="clearGroupBy"
          @clearFilterBy="clearFilterBy"
        />

        <TaskList
          v-if="activeView === 'LIST'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @select="editTask"
          @edit="editTask"
          @viewTask="showTaskDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
          @forwardTask="forwardTask"
          @copyTask="copyTask"
          @complete="saveTask"
        />

        <TaskKanban
          v-if="activeView !== 'LIST'"
          :task="items"
          :columns="columns"
          :select-column="activeView"
        />
      </template>
      <template v-else>
        <StateWrapper
          icon="mdi-cube-outline"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>

    <Pagination
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
      @loadAll="loadAllTasks"
    />

    <AddTask
      v-if="isAddTaskVisible"
      v-model="isAddTaskVisible"
      :form-settings="addTaskForm.settings"
      :panels="addTaskForm.panels"
      :secondary-panels="addTaskForm.secondaryPanels"
      :form-id="formId"
      :selected-task="selectedTask"
      :repository-id="repositoryId"
      :task-model="selectedTask"
      :mode="taskMode"
      :task-name="taskName"
      :task-workflow-list="taskWorkflowList"
      @history="showHistory"
      @close="isAddTaskVisible = false"
      @refresh="getTaskEntries"
    />

    <TaskFormListModal
      v-if="showTaskFormListModal"
      v-model="showTaskFormListModal"
      :task-form-list="taskFormList"
      @close="showTaskFormListModal = false"
      @selectTaskForm="getTaskFormId"
    />

    <ConfirmDeleteTask
      v-model="isDeleteConfirmationVisible"
      :task-id="selectedTask?.id"
      :task-name="taskName"
      @delete="updateTask"
    />

    <ConfirmRestoreTask
      v-model="isRestoreConfirmationVisible"
      :task-id="selectedTask?.id"
      :task-name="taskName"
      @restore="updateTask"
    />

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />
  </div>
</template>

<script>
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import TaskList from "@/views/tasks/views/tasks-browse-and-trash/components/TaskList";
import { form, workflow } from "@/api/factory.js";
import { omit } from "lodash-es";
import DataType from "@/constants/data-type.js";
import AddTask from "@/views/tasks/views/tasks-browse-and-trash/components/AddTask";
import TaskFormListModal from "@/views/tasks/views/tasks-browse-and-trash/components/TaskFormListModal";
// import { startCase, capitalize } from "lodash-es";
import TaskKanban from "@/views/tasks/views/tasks-browse-and-trash/components/TaskKanban";
import ConfirmDeleteTask from "@/views/tasks/views/tasks-browse-and-trash/components/ConfirmDeleteTask";
import ConfirmRestoreTask from "@/views/tasks/views/tasks-browse-and-trash/components/ConfirmRestoreTask";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";

export default {
  name: "TasksBrowseAndTrash",

  components: {
    Portal,
    StateWrapper,
    Pagination,
    ItemActions,
    ItemActionChips,
    TaskList,
    AddTask,
    TaskFormListModal,
    TaskKanban,
    ConfirmDeleteTask,
    ConfirmRestoreTask,
    ProcessHistory,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      addTaskDisabled: true,
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "type",
          label: "Type",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "status",
          label: "Status",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
      ],
      tasks: [],
      tasksData: [],
      activeView: "LIST",
      selectedTask: {},
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      isAddTaskVisible: false,
      addTaskForm: [],
      taskFormList: [],
      showTaskFormListModal: false,
      formId: 0,
      repositoryId: 0,
      taskModel: {},
      taskMode: "",
      workflowId: 0,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      taskName: "",
      kanbanList: [],
      workflowList: [],
      taskWorkflowList: [],
      processInfo: [],
      workflowCount: 0,
      workflowFormTaskList: {},
      processHistoryModal: false,
      formList: {},
      formStage: {},
      formDetails: {},
      formIdName: "",
      formModel: {},
      workflowRequestList: [],
      simpleSearchApplyed: false,
      selectedProcess: {},
      historyProcessId: 0,
    };
  },

  computed: {
    formFields() {
      const fields = [];

      if (!this.addTaskForm) {
        return fields;
      }

      // console.log(this.addTaskForm.panels);
      if (this.addTaskForm.panels) {
        for (let panel of this.addTaskForm.panels) {
          for (let field of panel.fields) {
            fields.push({
              id: field.id,
              type: field.type,
              label: field.label,
            });
          }
        }

        return fields;
      }
      return fields;
    },

    items() {
      return this.tasks.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((entry) => ({
          id: entry.itemid,
          // itemid: `Entry #${entry.itemid}`,
          link: true,
          icon:
            this.getStatus(entry) === "Closed"
              ? "mdi-progress-close"
              : this.getStatusData(entry),
          color:
            this.getStatus(entry) === "Closed"
              ? "green"
              : this.getStatusDataColor(entry),
          ...this.getFormFieldsData(entry),
          createdBy: entry.createdByEmail,
          createdAt: this.$day.format(entry.createdAt),
          modifiedBy: entry.modifiedByEmail,
          modifiedAt: this.$day.format(entry.modifiedAt),
          buttonAction: true,
          formId: this.formId,
          status: this.getStatus(entry),
        })),
      }));
    },

    showAddTask() {
      if (this.$store.state.profileMenus.length)
        return this.$store.state.profileMenus.find((row) => row.tasks).Menu[
          "Manage Task"
        ];

      return false;
    },
  },

  watch: {
    formFields: {
      // immediate: true,
      // deep: true,
      handler() {
        if (this.formFields.length) {
          const staticColumns = [
            // {
            //   id: this.$nano.id(),
            //   name: "itemid",
            //   label: "Entry #",
            //   type: "TITLE",
            //   isVisible: false,
            //   isSortable: false,
            //   isGroupable: false,
            //   dataType: DataType.SHORT_TEXT,
            // },
            {
              id: this.$nano.id(),
              name: "createdBy",
              label: "Created By",
              type: "SUBTITLE",
              isVisible: true,
              isSortable: true,
              isGroupable: true,
              dataType: DataType.SINGLE_SELECT,
            },
            {
              id: this.$nano.id(),
              name: "createdAt",
              label: "Created At",
              type: "NORMAL",
              isVisible: false,
              isSortable: true,
              isGroupable: false,
              dataType: DataType.DATE,
            },
            {
              id: this.$nano.id(),
              name: "modifiedBy",
              label: "Modified By",
              type: "NORMAL",
              isVisible: false,
              isSortable: true,
              isGroupable: true,
              dataType: DataType.SINGLE_SELECT,
            },
            {
              id: this.$nano.id(),
              name: "modifiedAt",
              label: "Modified At",
              type: "NORMAL",
              isVisible: false,
              isSortable: true,
              isGroupable: false,
              dataType: DataType.DATE,
            },
          ];

          const dynamicColumns = this.formFields
            .filter((field) => this.isValidField(field.type))
            .map((field, index) => ({
              id: this.$nano.id(),
              name: field.id,
              label: field.label || field.type,
              type: index == 0 ? "TITLE" : "NORMAL",
              isVisible:
                field.label == "Reminder"
                  ? false
                  : field.label == "Notification"
                  ? false
                  : true,
              isSortable: false,
              isGroupable: false,
              apiLoad: true,
              dataType: field.type,
            }));

          //const [entryId, ...rest] = staticColumns;
          // dynamicColumns[0].type = "TITLE";

          //this.columns = [entryId, ...dynamicColumns, ...rest];

          this.columns = [...dynamicColumns, ...staticColumns];

          this.columns.forEach((item) => {
            if (item.dataType === "SINGLE_SELECT") {
              this.kanbanList.push({
                id: this.$nano.id(),
                label: item.label,
                name: item.name,
              });
            }
          });
        }
      },
    },

    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.defaultView();
      },
    },

    "$store.state.defaultView": function () {
      this.defaultView();
    },
  },

  async created() {
    await this.getTaskForms();
  },

  methods: {
    defaultView() {
      let options = this.$store.state.defaultView;
      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      if (Object.keys(options).length) {
        if (options[menu]) {
          let view = options[menu].find((item) => item.subMenu === subMenu);
          if (view) {
            this.activeView = view.view;
          }
        }
      }
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) {
        this.filterBy = [];
      }
      this.getTaskEntries();
    },

    loadAllTasks() {
      this.itemsPerPage = this.totalItems || 1;
      this.currentPage = 1;
    },

    async getUserWorkflow() {
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getUserWorkflow();
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.workflowList = payload;

        this.workflowList.forEach(async (field) => {
          let wflowJson = JSON.parse(field.flowJson);
          let startBlock = wflowJson.blocks.find(
            (block) => block.type === "START"
          );
          if (
            startBlock.settings.users.indexOf(this.$store.state.session.id) > -1
          ) {
            //await this.getWorkflowList(field.id);

            if (this.processInfo === null) {
              this.workflowCount = field.name;
            } else {
              this.workflowCount = `${field.name} - (${this.processInfo.length})`;
            }
            if (
              field.initiatedBy === "FORM" ||
              field.initiatedBy === "DOCUMENT_FORM"
            ) {
              let actions = [];
              let rules = wflowJson.rules.find(
                (rule) => rule.fromBlockId === startBlock.id
              );
              if (rules) {
                actions.push(rules.proceedAction);
              }
              this.taskWorkflowList.push({
                id: field.id,
                label: this.workflowCount,
                formId: field.wFormId,
                stage: startBlock.settings.label,
                actions: actions,
              });
            }
          }
        });
      }
    },

    addTasksList() {
      this.taskWorkflowList = [];
      if (this.taskFormList.length) {
        if (this.taskFormList.length === 1) {
          this.addTasks(this.taskFormList[0].id);
          this.formId = this.taskFormList[0].id;
          this.repositoryId = this.taskFormList[0].repositoryId;
        } else {
          this.showTaskFormListModal = true;
        }
      }
    },

    getTaskFormId(formId) {
      this.showTaskFormListModal = false;
      this.addTasks(formId);
      this.formId = formId;
      this.taskFormList.forEach((form) => {
        if (form.id === formId) {
          this.repositoryId = form.repositoryId;
        }
      });
    },

    activeKanbanView(view) {
      // console.log(view);
      this.activeView = view;
    },

    getStatus(entry) {
      let data = "";
      for (let field of this.formFields) {
        if (field.label == "Task Status") {
          data = entry[field.id];
        }
      }

      return data;
    },

    getStatusData(entry) {
      let data = "";
      for (let field of this.formFields) {
        if (field.label == "End Date" && entry[field.id]) {
          var today = new Date().toISOString().split("T")[0];
          var entryDate = new Date(entry[field.id]).toISOString().split("T")[0];
          if (new Date(entryDate) >= new Date(today)) {
            data = "mdi-progress-check";
          } else data = "mdi-progress-clock";
        }
      }
      return data;
    },

    getStatusDataColor(entry) {
      let data = "";
      for (let field of this.formFields) {
        if (field.label == "End Date" && entry[field.id]) {
          var today = new Date().toISOString().split("T")[0];
          var entryDate = new Date(entry[field.id]).toISOString().split("T")[0];
          if (new Date(entryDate) >= new Date(today)) {
            data = "warning";
          } else data = "red";
        }
      }
      return data;
    },

    getFormFieldsData(entry) {
      const data = {};
      // console.log(entry);
      for (let field of this.formFields) {
        const complexTypes = [
          "TABLE",
          "MATRIX",
          "FILL_IN_THE_BLANKS",
          "TABS",
          "POPUP",
        ];
        // console.log(field.label);
        if (field.label !== "Reminder" || field.label !== "Notification") {
          // if (field.label !== "Reminder" || field.label !== "Notification") {
          data[field.id] = complexTypes.includes(field.type)
            ? JSON.parse(entry[field.id])
            : entry[field.id];
        }
      }

      return data;
    },

    isValidField(fieldType) {
      return [
        "SHORT_TEXT",
        "LONG_TEXT",
        "NUMBER",
        "COUNTER",
        "CALCULATED",
        "DATE",
        "TIME",
        "DATE_TIME",
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "CHIPS",
      ].includes(fieldType);
    },

    findTask(taskId) {
      const tasks = [];
      this.items.forEach((item) => tasks.push(...item.data));
      return tasks.find((entry) => entry.id === taskId);
    },

    editTask(taskId) {
      this.taskWorkflowList = [];
      this.taskMode = "EDIT";
      this.selectedTask = this.findTask(taskId);
      this.selectedTaskName();
      this.getUserWorkflow();
      this.isAddTaskVisible = true;
    },

    selectedTaskName() {
      let task = this.columns.find((item) => item.type === "TITLE")["name"];
      this.taskName = this.selectedTask[task];
    },

    showDeleteConfirmation(taskId) {
      this.selectedTask = this.findTask(taskId);
      this.selectedTaskName();

      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(taskId) {
      this.selectedTask = this.findTask(taskId);
      this.selectedTaskName();
      this.isRestoreConfirmationVisible = true;
    },

    showTaskDetails(taskId) {
      this.taskMode = "VIEW";
      this.selectedTask = this.findTask(taskId);
      this.isAddTaskVisible = true;
    },

    copyTask(taskId) {
      this.taskMode = "COPY";
      this.selectedTask = this.findTask(taskId);
      this.selectedTaskName();

      this.isAddTaskVisible = true;
    },

    forwardTask(taskId) {
      this.taskMode = "FORWARD";
      this.selectedTask = this.findTask(taskId);
      this.selectedTaskName();

      this.isAddTaskVisible = true;
    },

    searchRow(search) {
      if (search) {
        this.tasks = this.tasksData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.tasks = this.tasksData;
      }
    },

    /* api functions */

    async addTasks(formId) {
      this.formId = formId;
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await form.getForm(formId);
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.kanbanList = [];
      this.addTaskForm = JSON.parse(payload.formJson);
      if (formId) this.isAddTaskVisible = true;
      this.taskMode = "NEW";
      this.selectedTask = {};
    },

    async getTaskForms() {
      this.addTaskDisabled = true;
      this.taskFormList = [];

      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;

      if (data.length) {
        if (data[0].value.length) {
          this.addTaskDisabled = false;
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskFormList.length === 1) {
            this.formId = this.taskFormList[0].id;
            this.repositoryId = this.taskFormList[0].repositoryId;
            this.addTaskForm = JSON.parse(this.taskFormList[0].formJson);
            this.getTaskEntries();
          } else {
            this.formId = this.taskFormList[0].id;
            this.repositoryId = this.taskFormList[0].repositoryId;
            this.addTaskForm = JSON.parse(this.taskFormList[0].formJson);
            this.getTaskEntries();
          }
        }
      }
    },

    async getTaskEntries() {
      this.$store.commit("showLoadingBarPage");
      let filter = [];
      let taskOwner = "",
        taskAssign = "";
      const panels = [
        ...this.addTaskForm.panels,
        ...this.addTaskForm.secondaryPanels,
      ];

      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      taskOwner = fields.find((field) => field.label === "Task Owner")?.id;
      taskAssign = fields.find((field) => field.label === "Task Assign")?.id;
      if (this.filterBy.length) {
        let filterCriteria = JSON.parse(JSON.stringify(this.filterBy));
        filter.push(
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          }
        );
        filterCriteria[0].groupCondition = "AND";
        filter.push(...filterCriteria);
      } else {
        filter = [
          {
            filters: [
              {
                criteria: taskOwner,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
          {
            filters: [
              {
                criteria: taskAssign,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "OR",
          },
        ];
      }
      const { error, payload } = await form.getFormEntries(this.formId, {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: filter,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      // console.log(data);

      this.tasks = data || [];
      this.tasksData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async updateTask(payload) {
      this.$store.commit("showLoadingBarPage");
      let input = {
        fields: payload,
      };
      const { error } = await form.saveFormEntry(
        this.formId,
        this.selectedTask.id,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$store.commit("hideLoadingBarPage");

      const mode = payload.isDeleted ? "deleted" : "restored";
      this.selectedTaskName();
      this.$alert.success(`"${this.taskName}" ${mode} successfully`);

      this.getTaskEntries();
    },

    async saveTask(taskId) {
      const fieldsModel = this.findTask(taskId);
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      const panels = [
        ...this.addTaskForm.panels,
        ...this.addTaskForm.secondaryPanels,
      ];

      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      let endDate = fields.find((field) => field.label === "End Date")?.id;
      let taskStatus = fields.find(
        (field) => field.label === "Task Status"
      )?.id;
      let taskName = fields.find((field) => field.label === "Task Name")?.id;
      fieldsModel[endDate] = formattedDate;
      fieldsModel[taskStatus] = "Closed";
      this.taskName = fieldsModel[taskName];
      const unNecessaryKeys = [
        "itemid",
        "createdBy",
        "createdAt",
        "modifiedBy",
        "modifiedAt",
        "icon",
        "buttonAction",
        "formId",
        "status",
        "color",
        "link",
        "selected",
        "isDeleted",
        "createdByEmail",
        "modifiedByEmail",
        "emailStatus",
        "expandSubworkflow",
      ];

      const _fieldsModel = omit(fieldsModel, unNecessaryKeys);
      // console.log(_fieldsModel);

      try {
        this.$store.commit("showLoadingBarPage");

        const { id, ...payload } = _fieldsModel;
        // console.log(id, payload);
        let entryId = 0;

        if (this.mode === "COPY") {
          entryId = 0;
        } else {
          entryId = id || 0;
        }
        let input = {};
        if (entryId) {
          input = {
            fields: payload,
          };
        } else {
          let uploadedIdList = [];
          if (this.attachmentList.length) {
            this.attachmentList.forEach((file) => {
              uploadedIdList.push(file.id);
            });
          }
          input = {
            fields: payload,
            task: {
              comments: this.commentsList,
              fileIds: uploadedIdList,
            },
          };
        }

        const { error } = await form.saveFormEntry(this.formId, entryId, input);

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (id) this.$alert.success(`${this.taskName} Status Completed`);
        this.getTaskEntries();
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit("hideLoadingBarPage");
      }
    },

    async getWorkflowList(workflowId) {
      let inputData = {};
      inputData = {
        wId: workflowId,
        fId: this.selectedTask.formId,
        entryId: this.selectedTask.id,
      };
      const { error, payload } = await form.getWorkflowTaskList(
        workflowId,
        this.selectedTask.formId,
        this.selectedTask.id,
        inputData
      );
      this.workflowFormTaskList = payload;
      this.processInfo = payload.processInfo;

      if (error) {
        this.$alert.error(error);
        return;
      }
    },
    async getMyInboxList(workflowId) {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      this.$store.commit("showLoadingBarPage");
      let filterBy = {
        filterBy: this.filterBy,
        itemsPerPage: 100, //this.itemsPerPage,
        currentPage: this.currentPage,
      };
      const { error, payload } = await workflow.getMyInboxList(filterBy);
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      this.totalItems = payload.meta.totalItems;
      payload.data.forEach((row) => {
        let listAction = [];
        let actionFields = [];
        if (row.key && row.value.length) {
          this.selectedWorkflow = this.workflowList.find(
            (workflow) => workflow.id == workflowId
          );
          let wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
          wflowJson.rules.forEach((rule) => {
            if (rule.fromBlockId === row.value[0].activityId) {
              let icon = "",
                color = "";
              switch (rule.proceedAction) {
                case "Forward":
                case "Reply":
                  icon = "mdi-arrow-right";
                  color = "orange";
                  break;
                case "Approve":
                case "Complete":
                case "Rightsize":
                  icon = "mdi-check";
                  color = "positive";
                  break;
                case "Reject":
                case "Terminate":
                  icon = "mdi-close";
                  color = "red";
                  break;
                default:
                  icon = "mdi-arrow-left";
                  color = "primary";
                  break;
              }
              listAction.push({
                id: this.$nano.id(),
                name: rule.proceedAction,
                icon: icon,
                color: color,
              });
            }
          });
          wflowJson.blocks.forEach((block) => {
            if (block.id === row.value[0].activityId) {
              if (block.settings.formEditPartialAccess) {
                actionFields = block.settings.formEditControls;
              }
              if (block.settings.internalForward) {
                this.internalForward.proceed = true;
                this.internalForward.users = [];
                this.internalForward.groups = [];
                listAction.push({
                  id: this.$nano.id(),
                  name: "Forward",
                  icon: "mdi-arrow-right",
                  color: "orange",
                });
              }
            }
          });
        }
        if (this.currentPage === 1) {
          this.groupKey.push({
            name: row.key,
            checked: false,
            expand: true,
            actions: listAction,
            actionFields: actionFields,
            totalCount: row.totalCount,
            checkboxRequired: false,
          });
        }
        row.value[0].value.forEach((process) => {
          this.workflowRequestList.push({
            key: row.key,
            ...process,
            checked: false,
            starred: false,
            visible: true,
            checkboxRequired: false,
          });
        });
      });
    },

    async showHistory(processId, workflowId) {
      this.$store.commit("showLoadingBarPage");
      await this.getMyInboxList(workflowId);
      this.$store.commit("hideLoadingBarPage");
      this.selectedProcess = this.workflowRequestList.filter(
        (process) => process.processId === processId
      );
      this.historyProcessId = processId;
    },

    /* .... */
  },
};
</script>

<style lang="scss" scoped>
#task-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}

#modal #processHistory {
  #history {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    .comments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    .attachments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    #comments1 {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      // margin-bottom: 15px;
      background: #fcfafa;
      table-layout: fixed;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;

          &.small {
            width: 70px;
          }

          &.medium {
            width: 90px;
          }

          &.large {
            width: 210px;
          }
        }
      }

      th {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;

        &.commentsText {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.commentsText:hover {
          overflow: visible;
          white-space: normal;
          word-break: break-all;
        }
      }
    }

    #attachments {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 15px;
      background: #fcfafa;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      .comments {
        color: var(--secondary);
        text-align: left;
        cursor: pointer;
      }
    }
  }
}
</style>
