import { render, staticRenderFns } from "./TaskList.vue?vue&type=template&id=15c37539&scoped=true&"
import script from "./TaskList.vue?vue&type=script&lang=js&"
export * from "./TaskList.vue?vue&type=script&lang=js&"
import style0 from "./TaskList.vue?vue&type=style&index=0&id=15c37539&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c37539",
  null
  
)

export default component.exports
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMenu,QList,QItem,QItemSection});qInstall(component, 'directives', {ClosePopup});
