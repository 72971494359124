<template>
  <div class="task-kanban row items-center">
    <div class="col-0-5">
      <BaseActionButton
        icon="eva-arrow-ios-back-outline"
        class="left q-mr-sm"
        color="gray-5"
        size="32px"
        :no-border="true"
        :is-flat="true"
        @click="leftScroll"
      ></BaseActionButton>
    </div>
    <div class="col">
      <div
        v-for="{ key, value, data } in items"
        :key="value"
        class="taskKanbanRow"
      >
        <div v-if="key" class="group-by">
          <span class="key">{{ key }}: </span>
          <span>{{ value }} </span>
          <span>({{ data.length }})</span>
        </div>

        <div class="row">
          <div v-for="(row, keyObj) in data" :key="keyObj" class="col-3">
            <div
              class="q-mr-md q-pa-sm"
              style="background: #dfe6e9; border-radius: 5px"
            >
              <div class="row">
                <q-chip
                  :color="getColor(keyObj)"
                  size="12px"
                  text-color="white"
                >
                  {{ keyObj || "None" }}({{ row.length }})
                </q-chip>
              </div>
              <BaseScrollbar height="calc(100vh - 320px)">
                <div v-for="item in row" :key="item.id" class="row">
                  <div
                    class="col-12 task-row q-mt-xs"
                    @mouseover="itemId = item.id"
                    @mouseleave="itemId = 0"
                  >
                    <div class="card">
                      <div class="content">
                        <div class="row items-center q-pa-sm">
                          <div class="col">
                            <div
                              class="title ellipsis"
                              :class="{ 'closed-title': keyObj === 'Closed' }"
                            >
                              {{ item[title.name] }}
                            </div>
                            <div class="border-bottom"></div>
                            <div class="row">
                              <template v-for="column in _columns">
                                <div
                                  v-if="column.label === 'Task Status'"
                                  :key="column.id"
                                  class="col-auto"
                                >
                                  <q-chip
                                    size="12px"
                                    :color="getColor(item['status'])"
                                    text-color="white"
                                  >
                                    {{ item["status"] }}
                                  </q-chip>
                                </div>
                                <div
                                  v-if="column.label === 'Priority'"
                                  :key="column.id"
                                  class="col-auto"
                                >
                                  <q-chip
                                    size="12px"
                                    :color="getColor(item[column.name])"
                                    text-color="white"
                                  >
                                    {{ item[column.name] }}
                                  </q-chip>
                                </div>
                                <div
                                  v-if="
                                    item.id === itemId &&
                                    column.label === 'Task Owner'
                                  "
                                  :key="column.id"
                                  class="col-auto text-right"
                                >
                                  <BaseActionButton
                                    v-tooltip.bottom="
                                      showDetails(item, column.name)
                                    "
                                    is-flat
                                    no-border
                                    icon="eva-eye-outline"
                                    color="gray"
                                  />
                                </div>
                              </template>
                            </div>

                            <div v-for="column in _columns" :key="column.id">
                              <!-- <template
                                v-if="
                                  item[column.name] &&
                                  column.name !== '11m7YRT-7kdLx-g6iGojc' &&
                                  column.name !== 'OiEECeSQCDLtPaSuOiX0x'
                                "
                              >
                                <div class="row q-pb-sm">
                                  <div class="col-auto label">
                                    {{ column.label }} :
                                  </div>

                                  <div class="col ellipsis q-ml-xs">
                                    {{ item[column.name] || "-" }}
                                  </div>
                                </div>
                              </template> -->
                              <template v-if="column.label === 'Task Assign'">
                                <div class="row items-center q-py-sm">
                                  <div class="col-auto label">
                                    <BaseIcon
                                      name="mdi-account"
                                      color="gray"
                                      size="18px"
                                    />
                                  </div>

                                  <div class="col ellipsis q-ml-xs">
                                    {{ item[column.name] || "-" }}
                                  </div>
                                </div></template
                              >

                              <div
                                :class="
                                  column.label === 'Start Date'
                                    ? 'row items-center'
                                    : ''
                                "
                              >
                                <div
                                  v-if="column.label === 'Start Date'"
                                  class="col-auto label"
                                >
                                  <BaseIcon
                                    name="mdi-calendar"
                                    color="gray"
                                    size="18px"
                                  />
                                </div>

                                <div
                                  class="row items-center col ellipsis q-ml-xs"
                                >
                                  <template
                                    v-if="column.label === 'Start Date'"
                                  >
                                    {{ item[column.name] || "-" }} -
                                  </template>
                                  <template v-if="column.label === 'End Date'">
                                    {{ item[column.name] }}
                                  </template>
                                </div>
                              </div>

                              <!-- <template>
                                <div class="row items-center q-pb-sm">
                                  <div class="col-auto label">
                                    <BaseIcon
                                      name="mdi-calendar"
                                      color="gray"
                                      size="18px"
                                    />
                                  </div>

                                  <div class="col ellipsis q-ml-xs">
                                    {{ item[column.name] || "-" }}
                                  </div>
                                </div></template
                              > -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BaseScrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-0-5">
      <BaseActionButton
        icon="eva-arrow-ios-forward-outline"
        class="right q-ml-sm"
        color="gray-5"
        size="32px"
        :no-border="true"
        :is-flat="true"
        @click="rightScroll"
      ></BaseActionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskKanban",

  components: {},

  props: {
    task: {
      type: Array,
      required: true,
    },

    columns: {
      type: Array,
      required: true,
    },

    selectColumn: {
      type: String,
      required: true,
    },
  },

  data() {
    return { itemId: 0 };
  },

  computed: {
    _columns() {
      return this.columns.filter(
        (column) => column.type === "NORMAL" && column.isVisible
      );
    },

    title() {
      return this.columns.find((column) => column.type === "TITLE");
    },

    subtitle() {
      return this.columns.find((column) => column.type === "SUBTITLE");
    },

    items() {
      return this.task.map(({ key, value, data }) => ({
        key: key,
        value: value,
        data: data.reduce((acc, obj) => {
          const key = obj[this.selectColumn];
          const curGroup = acc[key] ?? [];

          return { ...acc, [key]: [...curGroup, obj] };
        }, {}),
      }));
    },
  },

  watch: {},

  methods: {
    /* ... */
    getColor(label) {
      // console.log(label);
      if (label === "Closed" || label === "High") {
        return "red";
      } else if (label === "In Progress" || label === "Medium") {
        return "warning";
      } else if (label === "Open" || label === "Low") {
        return "secondary";
      } else if (label === "ReOpen") {
        return "primary";
      } else {
        return "primary";
      }
    },

    leftScroll() {
      const left = document.querySelector(".taskKanbanRow");
      left.scrollBy(-200, 0);
    },
    rightScroll() {
      const right = document.querySelector(".taskKanbanRow");
      right.scrollBy(200, 0);
    },

    showDetails(item, name) {
      return `Task Owner: ${item[name] || "-"}
      `;
    },
  },
};
</script>

<style lang="scss" scoped>
.task-kanban {
  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
    @extend .title-2;
    text-transform: capitalize;
  }

  .count {
    color: $secondary;
    font-weight: bold;
  }
}

.card {
  // background-color: #dff3f6;
  background-color: #fff;
  border-radius: 4px;
  height: 100%;
  // border: 1px solid $secondary;
  border: 1px solid #fff;
  // margin-right: 15px;

  // .content {
  //   width: 250px;
  // }

  // &:hover {
  //   cursor: pointer;
  // }

  .ellipsis {
    &.Low {
      color: $primary;
    }

    &.High {
      color: $negative;
    }

    &.Medium {
      color: $warning;
    }
  }

  .title {
    @extend .title-2;
    text-transform: capitalize;

    &.closed-title {
      text-decoration: line-through;
    }
  }

  .subtitle {
    color: var(--icon-color-inverted);
  }

  .label {
    color: var(--icon-color-inverted);
  }

  .stat {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    transition: all 0.25s ease;

    .label {
      color: var(--icon-color-inverted);
      text-decoration: underline;
    }

    &:hover {
      cursor: pointer;
      color: var(--secondary);
      background-color: var(--hover-bg-color);

      .label {
        color: var(--secondary);
      }
    }
  }

  .detailsCount {
    text-align: right;
    margin-right: 10px;
    margin-top: 8px;
  }
}

.taskKanbanRow {
  // display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 100%;
}

.row {
  flex-wrap: nowrap !important;
}

.border-bottom {
  height: 1px;
  border: 0;
  align-self: stretch;
  background-color: var(--divider-color);
  margin-top: 6px;
  margin-bottom: 6px;
}
</style>
