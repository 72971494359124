<template>
  <ItemList
    :columns="columns"
    :items="items"
    :mode="mode"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
    @complete="handleComplete"
  >
    <template #extraMenu>
      <q-menu
        transition-show="scale"
        transition-hide="scale"
        anchor="bottom end"
        self="top right"
        auto-close
      >
        <!-- <q-list style="min-width: 100px">
          <q-item v-close-popup clickable>
            <q-item-section>Copy Task </q-item-section>
          </q-item>
          <q-item v-close-popup clickable>
            <q-item-section>Forward Task </q-item-section>
          </q-item>
          <q-item v-close-popup clickable @click="handleView">
            <q-item-section>View Task </q-item-section>
          </q-item>
        </q-list> -->

        <BaseScrollbar :height="scrollbarHeight" width="190px" class="q-pb-sm">
          <ListItem
            v-for="list in moreList"
            :key="list.id"
            :icon="list.icon"
            :label="list.label"
            @click="handleList(list.name)"
          />
        </BaseScrollbar>
      </q-menu>
    </template>
    <template #status="{ value }">
      <div id="form-status" :class="{ draft: value === 'Draft' }">
        <BaseIcon name="mdi-circle" size="8px" inherit-color />

        <div class="q-ml-sm">{{ value }}</div>
      </div>
    </template>

    <template #entries="{ value, item }">
      <div class="stat" @click.stop="$emit('entries', item.id)">
        <div class="ellipsis">
          {{ value || "-" }}
        </div>
      </div>
    </template>
  </ItemList>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "FormList",

  components: { ItemList, ListItem },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      formId: 0,
      moreList: [
        {
          id: this.$nano.id(),
          name: "forward",
          label: "Forward Task",
          icon: "mdi-forward",
        },
        {
          id: this.$nano.id(),
          name: "copy",
          label: "Copy Task",
          icon: "mdi-content-copy",
        },
        {
          id: this.$nano.id(),
          name: "view",
          label: "View Task",
          icon: "mdi-file-document-box",
        },
      ],
    };
  },

  computed: {
    scrollbarHeight() {
      const height = this.moreList.length * 36;
      return height > 216 ? "276px" : `${height + 15}px`;
    },
  },

  methods: {
    handleSelect(formId) {
      this.$emit("select", formId);
    },

    handleEdit(formId) {
      this.$emit("edit", formId);
    },

    handleDelete(formId) {
      this.$emit("delete", formId);
    },

    handleRestore(formId) {
      this.$emit("restore", formId);
    },

    handleMore(formId) {
      this.formId = formId;
      // this.$emit("more", formId);
    },

    handleView() {
      this.$emit("viewTask", this.formId);
    },

    handleList(name) {
      this.$emit(`${name}Task`, this.formId);
    },

    handleComplete(taskId) {
      this.$emit("complete", taskId);
    },
  },
};
</script>

<style lang="scss" scoped>
#form-status {
  display: flex;
  align-items: center;
  color: var(--green);

  &.draft {
    color: var(--orange);
  }
}
</style>
